/*---- External -------------------------------------------------------------*/

import { createSelector } from '@reduxjs/toolkit'
import { useSelector } from 'react-redux'

/*---- Qualdesk -------------------------------------------------------------*/

import { RootState } from '../types'

/*---------------------------------------------------------------------------*/

const getWeeklyReports = (state: RootState) =>
  state.firestore.ordered.latestWeeklyReports

const getRetentionReports = (state: RootState) =>
  state.firestore.ordered.latestRetentionReports

const latestWeeklyReportSelector = createSelector(getWeeklyReports, (reports) =>
  !!reports ? reports[0] : undefined
)

const latestRetentionReportSelector = createSelector(
  getRetentionReports,
  (reports) => (!!reports ? reports[0] : undefined)
)

export const useLatestWeeklyReport = () =>
  useSelector(latestWeeklyReportSelector)

export const useLatestRetentionReport = () =>
  useSelector(latestRetentionReportSelector)
