import app from 'firebase/app'
import 'firebase/firestore'
import 'firebase/database'
import 'firebase/functions'
import 'firebase/auth'
import 'firebase/storage'

const config = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  databaseURL: process.env.REACT_APP_FIREBASE_DATABASE_URL,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_FIREBASE_APP_ID,
  region: process.env.REACT_APP_FIREBASE_REGION,
}

const runningLocally =
  window.location.hostname === 'localhost' ||
  window.location.hostname.startsWith('192.168.')

if (runningLocally && !process.env.REACT_APP_USE_LIVE_DATA) {
  config.databaseURL = `http://${window.location.hostname}:9000?ns=${process.env.GCLOUD_PROJECT}`
}

const firebase = app
const firebaseApp = firebase.initializeApp(config)
const firestore = firebaseApp.firestore()
const functions = firebaseApp.functions(config.region)
const storage = firebaseApp.storage()

if (runningLocally && !process.env.REACT_APP_USE_LIVE_DATA) {
  firestore.settings({
    host: `${window.location.hostname}:8080`,
    ssl: false,
  })

  functions.useEmulator(window.location.hostname, 5001)
}

export { app, functions, firestore, storage }
