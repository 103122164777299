"use strict";
/*---- External -------------------------------------------------------------*/
Object.defineProperty(exports, "__esModule", { value: true });
exports.ReportType = void 0;
/*---- Qualdesk -------------------------------------------------------------*/
/*---------------------------------------------------------------------------*/
var ReportType;
(function (ReportType) {
    ReportType["WEEKLY"] = "Weekly";
    ReportType["RETENTION"] = "Retention";
})(ReportType = exports.ReportType || (exports.ReportType = {}));
