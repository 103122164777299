/*---- External -------------------------------------------------------------*/

import React from 'react'

import { useFirestoreConnect } from 'react-redux-firebase'

/*---- Qualdesk -------------------------------------------------------------*/

import { useLatestWeeklyReport } from '../hooks/useLatestReport'

import { Report } from '../templates/reportTemplate'
import { BigNumber } from '../components/reports/BigNumber'
import { PeriodEnding } from '../components/reports/PeriodEnding'
import { ReportType } from 'dashboard-shared/lib/types/Report.types'

/*---------------------------------------------------------------------------*/

export const Weekly: React.FC = () => {
  useFirestoreConnect({
    collection: 'reports',
    orderBy: ['createdAt', 'desc'],
    where: ['reportType', '==', ReportType.WEEKLY],
    limit: 1,
    storeAs: 'latestWeeklyReports',
  })

  const latestReport = useLatestWeeklyReport()

  return (
    <div className="flex-grow flex flex-col justify-between">
      <Report>
        <BigNumber
          number={latestReport?.averageMinutesPerUserPerWeek}
          previous={latestReport?.previous?.averageMinutesPerUserPerWeek}
          label="Average minutes per user per week"
        />
        <BigNumber
          number={latestReport?.weeklyActiveUsers}
          previous={latestReport?.previous?.weeklyActiveUsers}
          label="Weekly Active Users"
        />
        <BigNumber
          number={latestReport?.totalMinutesPerWeek}
          previous={latestReport?.previous?.totalMinutesPerWeek}
          label="Total minutes per week"
        />
      </Report>
      <PeriodEnding label="Week" endMillis={latestReport?.endMillis} />
    </div>
  )
}
