/*---- External -------------------------------------------------------------*/

import React, { useEffect } from 'react'

/*---- Qualdesk -------------------------------------------------------------*/

import { provision } from '../config/azureAuth'

import { useAuthState } from '../hooks/useAuthState'

import { Loading } from '../pages/Loading'
import { SignIn } from '../pages/SignIn'
import { Welcome } from '../pages/Welcome'

/*---------------------------------------------------------------------------*/

export const AuthenticationCheck: React.FC = () => {
  const { loading, authenticated } = useAuthState()

  useEffect(() => {
    if (authenticated) {
      provision()
    }
  }, [authenticated])

  if (loading) {
    return <Loading />
  } else if (authenticated) {
    return <Welcome />
  } else {
    return <SignIn />
  }
}
