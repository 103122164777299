/*---- External -------------------------------------------------------------*/

/*---- Qualdesk -------------------------------------------------------------*/

import { TabConfig } from '../types'

/*---------------------------------------------------------------------------*/

export const TABS: TabConfig[] = [
  { label: 'Weekly', icon: 'vertical-bar-chart-asc', path: '/' },
  { label: 'Retention', icon: 'heat-grid', path: '/retention' },
]
