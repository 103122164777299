/*---- External -------------------------------------------------------------*/

import { useMemo } from 'react'

import { isLoaded, isEmpty, FirebaseReducer } from 'react-redux-firebase'

/*---- Qualdesk -------------------------------------------------------------*/

import { useTypedSelector } from '../config/redux'

/*---------------------------------------------------------------------------*/

interface AuthState {
  auth: FirebaseReducer.AuthState
  loading: boolean
  authenticated: boolean
}

export const useAuthState = (): AuthState => {
  const auth = useTypedSelector((state) => state.firebase.auth)
  const loading = !isLoaded(auth)
  const authenticated = !loading && !isEmpty(auth)

  return useMemo(
    () => ({
      auth,
      loading,
      authenticated,
    }),
    [auth, authenticated, loading]
  )
}
