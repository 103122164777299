/*---- External -------------------------------------------------------------*/

import React, { useMemo } from 'react'

import { useFirestoreConnect } from 'react-redux-firebase'

import { DateTime } from 'luxon'

import { Tag } from '@blueprintjs/core'

/*---- Qualdesk -------------------------------------------------------------*/

import { useLatestRetentionReport } from '../hooks/useLatestReport'

import {
  ReportType,
  TenantReport,
} from 'dashboard-shared/lib/types/Report.types'
import { RetentionStatus } from 'dashboard-shared/lib/types/Tenant.types'
import { BigNumber } from '../components/reports/BigNumber'

/*---------------------------------------------------------------------------*/

export const Retention: React.FC = () => {
  useFirestoreConnect({
    collection: 'reports',
    orderBy: ['createdAt', 'desc'],
    where: ['reportType', '==', ReportType.RETENTION],
    limit: 1,
    storeAs: 'latestRetentionReports',
  })

  const latestReport = useLatestRetentionReport()

  const tenants = useMemo(() => {
    if (!latestReport) {
      return undefined
    }
    const t = Object.values(latestReport.tenants)
    return t.sort((a, b) => a.tenantName.localeCompare(b.tenantName))
  }, [latestReport])

  if (!tenants) return null

  const retained =
    (tenants.filter((t) => t.retentionStatus !== RetentionStatus.CHURNED)
      .length /
      tenants.length) *
    100

  return (
    <>
      <div className="p-8 sm:p-16">
        <BigNumber
          number={retained}
          suffix="%"
          hideChange
          label="Week 4+ retention"
        />
      </div>
      <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-4 p-4 border-t border-border-normal-light dark:border-border-normal-dark">
        {tenants.map((r) => (
          <Tenant key={r.tenantName} {...r} />
        ))}
      </div>
    </>
  )
}

const Tenant: React.FC<TenantReport> = (t) => {
  const name = t.tenantName.replace('canvas-', '')
  return (
    <div className="flex flex-col space-y-2 p-4 bg-white dark:bg-black bg-opacity-30 rounded-sm">
      <div className="flex space-x-2 items-center pb-1 border-b border-border-normal-light dark:border-border-normal-dark">
        <div className="font-mono text-lg font-semibold mr-auto flex-shrink overflow-hidden overflow-ellipsis whitespace-nowrap">
          {name}
        </div>
        <Tag className="rounded-full font-semibold flex-shrink-0" minimal>
          {t.userTotal} user{t.userTotal > 1 ? 's' : ''}
        </Tag>
        {t.retentionStatus === RetentionStatus.CHURNED && (
          <Tag className="flex-shrink-0 bg-icon-danger-light dark:bg-icon-danger-dark text-white">
            Churned
          </Tag>
        )}
        {t.retentionStatus === RetentionStatus.DISENGAGED && (
          <Tag className="flex-shrink-0 bg-icon-warning-light dark:bg-icon-warning-dark text-white">
            Disengaged
          </Tag>
        )}
        {t.retentionStatus === RetentionStatus.ACTIVE && (
          <Tag className="flex-shrink-0 bg-icon-success-light dark:bg-icon-success-dark text-white">
            Active
          </Tag>
        )}
      </div>
      <div className="flex space-x-2 text-xs">
        <span className="text-text-secondary-light dark:text-text-secondary-dark">
          Last active:
        </span>
        <span className="font-medium">
          {DateTime.fromMillis(t.lastActive).toFormat('ccc d MMM yyyy')}
        </span>
      </div>
    </div>
  )
}
