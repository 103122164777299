/*---- External -------------------------------------------------------------*/

import React from 'react'

/*---- Qualdesk -------------------------------------------------------------*/

import { TabConfig } from '../../types'
import { Tab } from './Tab'

/*---------------------------------------------------------------------------*/

interface TabBarProps {
  tabs: TabConfig[]
  disabled?: boolean
}

export const TabBar: React.FC<TabBarProps> = ({ tabs, disabled }) => (
  <div className="flex-shrink-0 bg-background-five-light dark:bg-background-one-dark p-4 grid grid-flow-col auto-cols-fr">
    {tabs.map((tab, i) => (
      <Tab tab={tab} key={i} disabled={disabled} />
    ))}
  </div>
)
