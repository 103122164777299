/*---- External -------------------------------------------------------------*/

import React from 'react'

/*---- Qualdesk -------------------------------------------------------------*/

import { Page } from '../templates/pageTemplate'
import { Report } from '../templates/reportTemplate'

import { BigNumber } from '../components/reports/BigNumber'
import { TabBar } from '../components/tabs/TabBar'

/*---------------------------------------------------------------------------*/

export const Loading: React.FC = () => (
  <Page>
    <div className="h-full flex flex-col">
      <Report>
        <BigNumber />
        <BigNumber />
        <BigNumber />
      </Report>
      <TabBar tabs={[]} disabled />
    </div>
  </Page>
)
