/*---- External -------------------------------------------------------------*/

import React from 'react'

import { BrowserRouter as Router, Switch } from 'react-router-dom'

/*---- Qualdesk -------------------------------------------------------------*/

import { TabConfig } from '../../types'
import { TabBar } from './TabBar'

/*---------------------------------------------------------------------------*/

interface TabsProps {
  tabs: TabConfig[]
}

export const Tabs: React.FC<TabsProps> = ({ children, tabs }) => (
  <Router>
    <div className="h-full flex flex-col">
      <div className="flex-grow overflow-auto flex flex-col">
        <Switch>{children}</Switch>
      </div>
      <TabBar tabs={tabs} />
    </div>
  </Router>
)
