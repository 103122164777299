/*---- External -------------------------------------------------------------*/

import { combineReducers, configureStore } from '@reduxjs/toolkit'
import { createSelectorHook } from 'react-redux'

import { firebaseReducer } from 'react-redux-firebase'
import { firestoreReducer } from 'redux-firestore'

/*---- Qualdesk -------------------------------------------------------------*/

import { RootState } from '../types'

/*---------------------------------------------------------------------------*/

const rootReducer = combineReducers({
  firebase: firebaseReducer,
  firestore: firestoreReducer,
})

export const store = configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      // Don’t check for serializability –
      // the Firebase auth object isn’t and
      // this throws an error
      serializableCheck: false,
    }),
})

export const useTypedSelector = createSelectorHook<RootState>()
