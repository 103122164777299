/*---- External -------------------------------------------------------------*/

import React from 'react'
import { Icon } from '@blueprintjs/core'
import { NavLink } from 'react-router-dom'

/*---- Qualdesk -------------------------------------------------------------*/

import { TabConfig } from '../../types'

/*---------------------------------------------------------------------------*/

interface TabProps {
  tab: TabConfig
  disabled?: boolean
}

export const Tab: React.FC<TabProps> = ({ tab, disabled }) => (
  <NavLink
    activeClassName="text-text-accent-light dark:text-text-accent-dark hover:text-text-accent-light dark:hover:text-text-accent-dark"
    className="flex flex-col items-center space-y-1 select-none text-text-secondary-light dark:text-text-secondary-dark hover:no-underline hover:text-text-secondary-light dark:hover:text-text-secondary-dark"
    exact
    to={tab.path}
    role="button"
    aria-disabled={disabled}
  >
    <Icon icon={tab.icon} />
    <span className="text-xs font-semibold">{tab.label}</span>
  </NavLink>
)
