/*---- External -------------------------------------------------------------*/

import React from 'react'

/*---- Qualdesk -------------------------------------------------------------*/

import { LoadChange } from './LoadChange'
import { LoadLabel } from './LoadLabel'
import { LoadNumber } from './LoadNumber'

/*---------------------------------------------------------------------------*/

interface BigNumberProps {
  number?: number
  suffix?: string
  previous?: number
  label?: string
  hideChange?: boolean
  lowerNumbersBetter?: boolean
}

export const BigNumber: React.FC<BigNumberProps> = ({
  number,
  previous,
  label,
  suffix,
  hideChange,
  lowerNumbersBetter,
}) => (
  <div className="flex flex-col space-y-2 items-start text-text-primary-light dark:text-text-primary-dark">
    <LoadNumber
      className="text-6xl lg:text-huge font-semibold"
      number={number}
      suffix={suffix}
    />
    {!hideChange && (
      <LoadChange
        className="font-semibold text-3xl lg:text-5xl sm:pb-4"
        number={number}
        previous={previous}
        lowerNumbersBetter={lowerNumbersBetter}
      />
    )}
    <LoadLabel
      className="font-semibold text-lg lg:text-2xl text-text-secondary-light dark:text-text-secondary-dark"
      label={label}
    />
  </div>
)
