/*---- External -------------------------------------------------------------*/

import React from 'react'

/*---- Qualdesk -------------------------------------------------------------*/

import { ReactComponent as Icon } from '../../assets/qualdesk-icon.svg'

import { UserButton } from './UserButton'

/*---------------------------------------------------------------------------*/

export const Navigation: React.FC = () => (
  <div className="p-3 flex-shrink-0 flex justify-between items-center h-12 bg-background-two-light dark:bg-background-one-dark">
    <Icon className="w-6 h-6" />
    <UserButton />
  </div>
)
