/*---- External -------------------------------------------------------------*/

import React from 'react'

import { Classes } from '@blueprintjs/core'

import classNames from 'classnames'

/*---- Qualdesk -------------------------------------------------------------*/

/*---------------------------------------------------------------------------*/

interface LoadLabelProps {
  className?: string
  label?: string
}

export const LoadLabel: React.FC<LoadLabelProps> = ({ className, label }) => (
  <span
    className={classNames(className, {
      [Classes.SKELETON]: label === undefined,
    })}
  >
    {label === undefined ? 'This label is loading' : label}
  </span>
)
