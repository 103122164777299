/*---- External -------------------------------------------------------------*/

import { Client } from '@microsoft/microsoft-graph-client/lib/src/Client'
import { AuthProviderCallback } from '@microsoft/microsoft-graph-client/lib/src/IAuthProviderCallback'

import { DateTime } from 'luxon'

/*---- Qualdesk -------------------------------------------------------------*/

import { app, firestore, storage } from './firebase'

import { User } from '../types/User.types'

/*---------------------------------------------------------------------------*/

const provider = new app.auth.OAuthProvider('microsoft.com')

provider.setCustomParameters({
  tenant: process.env.REACT_APP_AZURE_AD_TENANT_ID,
})

const provision = async () => {
  const result = await app.auth().getRedirectResult()

  if (!result.credential || !result.user) {
    return Promise.resolve()
  }

  const userDocRef = firestore.collection('users').doc(result.user.uid)
  const accessToken = (result.credential as app.auth.OAuthCredential)
    .accessToken

  if (!accessToken) {
    return Promise.reject()
  }

  await userDocRef.set(
    {
      profile: result.additionalUserInfo?.profile,
    },
    { merge: true }
  )
  await userDocRef.collection('authentication').doc('azure').set({
    accessToken,
  })

  const userDoc = await userDocRef.get()
  const { photo } = userDoc.data() as User

  if (
    !!photo &&
    DateTime.fromSeconds(photo.lastUpdatedAt.seconds).diffNow('days').days > -7
  ) {
    return Promise.resolve()
  }

  const options = {
    authProvider: (callback: AuthProviderCallback) => {
      callback(null, accessToken)
    },
  }

  const microsoftGraphClient = Client.init(options)
  try {
    const photoBlob = await microsoftGraphClient
      .api('/me/photos/120x120/$value')
      .get()

    const storageRef = storage.ref().child('users').child(result.user.uid)

    await storageRef.put(photoBlob)

    const newPhotoUrl = await storageRef.getDownloadURL()

    await userDocRef.update({
      photo: {
        url: newPhotoUrl,
        lastUpdatedAt: app.firestore.FieldValue.serverTimestamp(),
      },
    })
  } catch (e) {
    console.log(`Couldn't find photo for current user`, e)
  }
}

export { provider, provision }
