/*---- External -------------------------------------------------------------*/

import { createSelector } from '@reduxjs/toolkit'
import { useSelector } from 'react-redux'

/*---- Qualdesk -------------------------------------------------------------*/

import { RootState } from '../types'

/*---------------------------------------------------------------------------*/

const userDetailsSelector = createSelector(
  (state: RootState) => state.firebase.auth.uid,
  (state: RootState) => state.firestore.data.users,
  (uid, users) => {
    if (!uid || !users) {
      return undefined
    }
    return users[uid]
  }
)

export const useUserDetails = () => useSelector(userDetailsSelector)
