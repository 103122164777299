/*---- External -------------------------------------------------------------*/

import React from 'react'

import { Button, Menu, Popover } from '@blueprintjs/core'

/*---- Qualdesk -------------------------------------------------------------*/

import { app } from '../../config/firebase'

import { useAuthState } from '../../hooks/useAuthState'
import { UserBadge } from '../user/UserBadge'
import { useUserDetails } from '../../hooks/useUserDetails'

/*---------------------------------------------------------------------------*/

export const UserButton: React.FC = () => (
  <Popover minimal>
    <Button minimal>
      <UserBadge />
    </Button>
    <UserMenu />
  </Popover>
)

export const UserMenu: React.FC = () => {
  const { authenticated } = useAuthState()
  const userDetails = useUserDetails()

  const handleSignOut = () => {
    app.auth().signOut()
  }

  if (!authenticated) {
    return null
  }

  return (
    <Menu>
      {userDetails?.profile?.jobTitle && (
        <Menu.Divider title={userDetails.profile.jobTitle} />
      )}
      <Menu.Item
        icon="user"
        href="https://myaccount.microsoft.com/"
        target="_blank"
        text="My account"
      />
      <Menu.Divider />
      <Menu.Item
        icon="log-out"
        onClick={handleSignOut}
        text="Sign out of Dashboard"
      />
    </Menu>
  )
}
