/*---- External -------------------------------------------------------------*/

import React, { useEffect } from 'react'

import { Provider as ReduxProvider } from 'react-redux'

import { ReactReduxFirebaseProvider } from 'react-redux-firebase'
import { createFirestoreInstance } from 'redux-firestore'

import { FocusStyleManager } from '@blueprintjs/core'

/*---- Qualdesk -------------------------------------------------------------*/

import { app } from './config/firebase'
import { store } from './config/redux'

import './index.css'

import { AuthenticationCheck } from './router/AuthenticationCheck'

/*---------------------------------------------------------------------------*/

FocusStyleManager.onlyShowFocusOnTabs()

const rrfConfig = {
  userProfile: 'users',
  useFirestoreForProfile: true,
}

const rrfProps = {
  firebase: app,
  config: rrfConfig,
  dispatch: store.dispatch,
  createFirestoreInstance,
}

export const App: React.FC = () => {
  useEffect(() => {
    const setVh = () => {
      const vh = window.innerHeight * 0.01
      document.documentElement.style.setProperty('--vh', `${vh}px`)
    }

    window.addEventListener('load', setVh)
    window.addEventListener('resize', setVh)
  }, [])

  return (
    <ReduxProvider store={store}>
      <ReactReduxFirebaseProvider {...rrfProps}>
        <AuthenticationCheck />
      </ReactReduxFirebaseProvider>
    </ReduxProvider>
  )
}
