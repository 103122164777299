/*---- External -------------------------------------------------------------*/

import React from 'react'

import { Classes } from '@blueprintjs/core'

import classNames from 'classnames'

/*---- Qualdesk -------------------------------------------------------------*/

import { numberToCommaString } from 'dashboard-shared/lib/helpers/numberToCommaString'

/*---------------------------------------------------------------------------*/

interface LoadNumberProps {
  className?: string
  number?: number
  suffix?: string
}

export const LoadNumber: React.FC<LoadNumberProps> = ({
  className,
  number,
  suffix,
}) => (
  <span
    className={classNames(className, {
      [Classes.SKELETON]: number === undefined,
    })}
  >
    {number === undefined ? '00' : numberToCommaString(Math.round(number))}
    {suffix}
  </span>
)
