/*---- External -------------------------------------------------------------*/

import React, { useMemo } from 'react'

import { Classes } from '@blueprintjs/core'

import classNames from 'classnames'

/*---- Qualdesk -------------------------------------------------------------*/

import { numberToCommaString } from 'dashboard-shared/lib/helpers/numberToCommaString'

/*---------------------------------------------------------------------------*/

interface LoadChangeProps {
  className?: string
  number?: number
  previous?: number
  lowerNumbersBetter?: boolean
}

export const LoadChange: React.FC<LoadChangeProps> = ({
  className,
  number,
  previous,
  lowerNumbersBetter,
}) => {
  const changeValue = useMemo((): number | undefined => {
    if (number === undefined || previous === undefined) {
      return undefined
    } else if (previous === 0 && number === 0) {
      return 0
    } else if (previous === 0 && number > 0) {
      return Infinity
    } else {
      return Math.round(((number - previous) / previous) * 100)
    }
  }, [number, previous])

  const changeString = useMemo((): string => {
    if (changeValue === undefined) {
      return '00%'
    } else if (changeValue === Infinity) {
      return '∞% ▲'
    } else if (changeValue > 0) {
      return `${numberToCommaString(changeValue)}% ▲`
    } else if (changeValue < 0) {
      return `${numberToCommaString(Math.abs(changeValue))}% ▼`
    } else {
      return `–`
    }
  }, [changeValue])

  return (
    <span
      className={classNames(className, 'whitespace-no-wrap', {
        [Classes.SKELETON]: changeValue === undefined,
        'text-icon-success-light dark:text-icon-success-dark':
          changeValue !== undefined &&
          ((changeValue > 0 && !lowerNumbersBetter) ||
            (changeValue < 0 && lowerNumbersBetter)),
        'text-icon-danger-light dark:text-icon-danger-dark':
          changeValue !== undefined &&
          ((changeValue < 0 && !lowerNumbersBetter) ||
            (changeValue > 0 && lowerNumbersBetter)),
        'text-text-secondary-light dark:text-text-secondary-dark':
          changeValue !== undefined && changeValue === 0,
      })}
    >
      {changeString}
    </span>
  )
}
