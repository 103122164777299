/*---- External -------------------------------------------------------------*/

import React from 'react'

import { DateTime } from 'luxon'

/*---- Qualdesk -------------------------------------------------------------*/

import { DATE_FORMAT } from '../../config/formats'

/*---------------------------------------------------------------------------*/

interface PeriodEndingProps {
  label: string
  endMillis: number | undefined
}

export const PeriodEnding: React.FC<PeriodEndingProps> = ({
  label,
  endMillis,
}) => {
  if (!endMillis) {
    return null
  }

  const dateLabel = DateTime.fromMillis(endMillis).toFormat(DATE_FORMAT)

  return (
    <div className="p-4 mt-auto text-xs text-text-secondary-light dark:text-text-secondary-dark">
      {label} ending {dateLabel}
    </div>
  )
}
