/*---- External -------------------------------------------------------------*/

import React from 'react'

/*---- Qualdesk -------------------------------------------------------------*/

/*---------------------------------------------------------------------------*/

export const Report: React.FC = ({ children }) => (
  <div className="flex-grow flex flex-col">
    <div className="p-8 sm:p-16 gap-8 sm:gap-16 grid grid-cols-1 sm:grid-cols-3 my-auto">
      {children}
    </div>
  </div>
)
