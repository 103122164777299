/*---- External -------------------------------------------------------------*/

import React from 'react'

import { Button, Callout } from '@blueprintjs/core'

/*---- Qualdesk -------------------------------------------------------------*/

import { provider } from '../config/azureAuth'
import { app } from '../config/firebase'

import { Page } from '../templates/pageTemplate'

/*---------------------------------------------------------------------------*/

export const SignIn: React.FC = () => {
  const handleSignIn = () => {
    app.auth().signInWithRedirect(provider)
  }

  return (
    <Page>
      <div className="h-full flex-grow flex flex-col items-center justify-center">
        <Callout className="flex flex-col space-y-2 p-4 w-auto">
          <div className="text-lg font-semibold">Qualdesk Dashboard</div>
          <Button onClick={handleSignIn} text="Employee sign in" />
        </Callout>
      </div>
    </Page>
  )
}
