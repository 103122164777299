/*---- External -------------------------------------------------------------*/

import React from 'react'

import { useFirestoreConnect } from 'react-redux-firebase'
import { Route } from 'react-router-dom'

/*---- Qualdesk -------------------------------------------------------------*/

import { Page } from '../templates/pageTemplate'

import { Weekly } from '../dashboards/Weekly'
import { Retention } from '../dashboards/Retention'

import { Tabs } from '../components/tabs/Tabs'

import { TABS } from '../config/tabs'

/*---------------------------------------------------------------------------*/

export const Welcome: React.FC = () => {
  useFirestoreConnect(['users'])

  return (
    <Page>
      <Tabs tabs={TABS}>
        <Route path="/" exact>
          <Weekly />
        </Route>
        <Route path="/retention">
          <Retention />
        </Route>
      </Tabs>
    </Page>
  )
}
