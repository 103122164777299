/*---- External -------------------------------------------------------------*/

import React from 'react'

import { Classes } from '@blueprintjs/core'

import classNames from 'classnames'

/*---- Qualdesk -------------------------------------------------------------*/

import { Navigation } from '../components/navigation/Navigation'

import { useDarkMode } from '../hooks/useDarkMode'

/*---------------------------------------------------------------------------*/

export const Page: React.FC = ({ children }) => {
  const darkMode = useDarkMode()

  return (
    <div
      className={classNames(
        'h-full flex flex-col bg-app-background dark:bg-dark-app-background',
        {
          [Classes.DARK]: darkMode,
        }
      )}
    >
      <Navigation />
      <div className="overflow-auto flex-1 flex-col">{children}</div>
    </div>
  )
}
