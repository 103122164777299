/*---- External -------------------------------------------------------------*/

import React from 'react'

/*---- Qualdesk -------------------------------------------------------------*/

import { useAuthState } from '../../hooks/useAuthState'
import { useUserDetails } from '../../hooks/useUserDetails'

/*---------------------------------------------------------------------------*/

export const UserBadge: React.FC = () => {
  const { authenticated, loading, auth } = useAuthState()
  const user = useUserDetails()

  if (!loading && !authenticated) {
    return null
  }

  const photoUrl = user?.photo?.url
  const displayName = !!auth.displayName ? auth.displayName : 'Loading'

  return (
    <div className="flex flex-row font-light space-x-2 items-center">
      {!!photoUrl ? (
        <img
          alt=""
          className="rounded-full bg-text-secondary-light dark:bg-text-secondary-dark w-5 h-5 text-center opacity-75"
          src={photoUrl}
        />
      ) : (
        <div className="rounded-full bg-text-secondary-light dark:bg-text-secondary-dark w-5 h-5" />
      )}
      <span
        className={
          loading
            ? 'bp3-skeleton'
            : 'text-text-secondary-light dark:text-text-secondary-dark'
        }
      >
        {displayName}
      </span>
    </div>
  )
}
