/*---- External -------------------------------------------------------------*/

import React from 'react'
import ReactDOM from 'react-dom'

import * as Sentry from '@sentry/react'
import { Integrations as TracingIntegrations } from '@sentry/tracing'

/*---- Qualdesk -------------------------------------------------------------*/

import { App } from './App'

/*---------------------------------------------------------------------------*/

if (process.env.NODE_ENV === 'production') {
  Sentry.init({
    release: process.env.REACT_APP_SENTRY_RELEASE,
    environment: process.env.REACT_APP_SENTRY_ENVIRONMENT,
    dsn:
      'https://b4fdbd1512b44ba994371dbfbc220e96@o408047.ingest.sentry.io/5508714',

    normalizeDepth: 10, // for Redux state

    integrations: [new TracingIntegrations.BrowserTracing()],
    tracesSampleRate: 0.5,

    beforeSend(event, hint) {
      if (event.exception) {
        Sentry.showReportDialog({ eventId: event.event_id })
      }
      return event
    },
  })
}

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById('root')
)
